<template>
  <!--  <div>-->
  <!--    <div class="surroundContainer transition_none">-->
  <!--      <div class="header">-->
  <!--        <div class="icon"></div>-->
  <!--        <div class="title">碳中和效益</div>-->
  <!--      </div>-->
  <!--      <div class="chart-box">-->
  <!--       -->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <surroundBox desc="减少碳排放量" name="碳中和效益">
    <template slot="content">
      <div class="chart-box-1">
        <div class="item-box">
          <div class="item">
            <div>
              <p>{{ value2 }}{{ unit2 }}</p>
              <p>{{ text2 }}</p>
            </div>
            <div>
              <p>{{ value1 }}{{ unit1 }}</p>
              <p>{{ text1 }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </surroundBox>
</template>

<script>

import surroundBox from "../surroundBox/surroundBox";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      value1: '',
      unit1: '',
      unit2: '',
      value2: '',
      text1: '',
      text2: ''
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.datas = []
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 167
      }).then(res => {
        if (res && res.length > 0) {
          res.forEach((item) => {
            if (item.id === 144) {
              //  "温室气体排放减少"
              this.value1 = item.content
              this.unit1 = item.contentUnit
              this.text1 = item.dimensionName
            } else {
              this.value2 = item.content
              this.unit2 = item.contentUnit
              this.text2 = item.dimensionName
            }
          })
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
//@import url("../common.less");

.chart-box-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item-box {
  .item {
    width: 2.4rem;
    height: 19.167vh;
    background-image: url("~@/assets/imgs/carbonItemBox.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 1.85vh 0;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-direction: column;

    div {
      width: 100%;
      height: 7.40vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;


      p:first-child {
        color: #2EFFEA;
        font-size: 0.2rem;
      }

      p:last-child {
        color: #DAE1EB;
        font-size: 0.14rem;
      }
    }
  }
}
</style>
